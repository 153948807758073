import { ref, computed, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
// import { da } from 'date-fns/locale'
// import { da } from 'date-fns/locale'

/**
 * Logic for fetching list into the table
 * Will use in many component, be careful when want to change this function
 * @param {object} payload
 * @param {string} payload.url
 */
export default function useListTablePage (payload) {
  const { $get }       = useHttp()
  const isBusy         = ref(true)
  const refListTable   = ref(null)
  const perPage        = ref(15)
  const perPageDefault = ref(15)
  const itemLists      = ref([])
  const totalList      = ref(0)
  const currentPage    = ref(1)
  const currentPage2    = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery    = ref('')
  const customSearchQuery    = ref('')
  const sortBy         = ref('code')
  const isSortDirDesc  = ref(true)
  const statusFilter   = ref(null)
  const totalCounts    = ref(0)
  const itemLists2     = ref([])


  const dataMeta =  computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0

    if(currentPage2.value != currentPage.value){
      currentPage2.value = currentPage.value
      loadPage()
    }    
    return {
      from: (perPage.value * (currentPage.value - 1)) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalList.value
    }
  })


  // fetch data to api endpoint
  const fetchLists = async (url = payload.url, urlcount = payload.urlcount) => {
    let counts = 0
    try {
      if (payload.urlcount) {
        counts = await $get({
          url: urlcount
        })
        totalCounts.value = counts.count
      }
    } catch (e) {
      isBusy.value = false
    }

    if (url) {
      try {
        const { data, count = 0 } = await $get({
          url: `${url}limit=${currentPage.value}${customSearchQuery.value}`
        })

        isBusy.value     = false
        itemLists.value  = data
        totalList.value  = count ? count : counts.count
        perPage.value    = (count || data.length) < perPageDefault.value ? (count || data.length) : perPageDefault.value
      } catch (e) {
        isBusy.value     = false
      }
    }
  }

  // fetch data to api endpoint
  const loadPage = async (url = payload.url) => {
    try {
      const { data, count = 0 } = await $get({ url: `${url}&limit=${currentPage.value}${customSearchQuery.value}` })
      itemLists2.value  = data
      itemLists.value    = itemLists2.value
      totalList.value  = count
      perPage.value    = (count || data.length) < perPageDefault.value ? (count || data.length) : perPageDefault.value
    }catch (e) {
      isBusy.value = false
    }

    return 1
  }

  const changePerPage = (page) => {
    perPage.value = page
    perPageDefault.value = page
  }

  onMounted(fetchLists)

  return {
    isBusy,
    itemLists,
    perPage,
    currentPage,
    totalList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    statusFilter,
    fetchLists,
    changePerPage,
    loadPage,
    customSearchQuery
  }
}
